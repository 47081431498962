<template>
  <ion-page>
    <Header></Header>
    <ion-content :fullscreen="true" class="ion-padding-start ion-padding-end ion-padding-top">
      <template v-for="(lineFavourites, line) in state.favourites" :key="line">
        <template v-if="lineFavourites.length">
          <ion-card color="light" class="ion-margin-start">
            <ion-card-header>
              <ion-card-title><img :src="'/assets/img/'+ line +'-logo.webp'" :alt="line" class="title-logo"></ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <ion-list lines="none">
                <template v-for="(favourite) in lineFavourites" :key="favourite.name">
                  <ion-item>
                    <ion-label>
                      <ion-button size="large" fill="clear" @click="openModal(favourite.name, line, favourite.customName)">{{ favourite.customName }}</ion-button>
                    </ion-label>
                    <ion-note slot="end">
                      <ion-button size="large" fill="clear" @click="removeFavourite(line, favourite.name)">
                        <ion-icon :icon="trash"/>
                      </ion-button>
                    </ion-note>
                  </ion-item>

                </template>
              </ion-list>
            </ion-card-content>
          </ion-card>
        </template>
      </template>
      <ion-button size="default" fill="outline" @click="clearFavourites()" class="ion-margin-start">Clear All Favorites</ion-button>
    </ion-content>
  </ion-page>
</template>

<script lang="js">
import store from '@/store'
import {IonPage, IonContent, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonList, IonItem, IonLabel, IonNote, IonIcon, modalController} from '@ionic/vue';
import {trash} from 'ionicons/icons';
import Header from "@/components/global/Header";
import CrimpData from "@/components/CrimpData";


export default {
  name: 'Favourites',
  components: {Header, IonContent, IonPage, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonList, IonItem, IonLabel, IonNote, IonIcon},
  setup() {
    return {
      trash,
    }
  },
  mounted() {
    //store.removeFavourite(this.state.line, '')
  },
  data() {
    return {
      state: store.state,
    }
  },
  methods: {
    removeFavourite(line, item) {
      store.removeFavourite(line, item)
    },
    clearFavourites() {
      store.clearFavourites();
    },
    async openModal(crimpSpecId, line, customName) {
      const modal = await modalController
          .create({
            component: CrimpData,
            cssClass: 'crimp-spec',
            componentProps: {
              title: customName,
              crimpSpecId: crimpSpecId,
              line: line,
              fromFavourites: true
            },
          })
      return modal.present();
    },
  },

}
</script>

<style>
.title-logo {
  max-width: 150px;
}
</style>
